import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '@/app/store';
import { studentApi } from '@/services/student';
import { definitions } from '@/types/schema';

interface ICspStudentSlice {
  studentData?: definitions['ResponseWrapper«StudentDetailsViewDto»'];
}
const initialState = {
  studentData: {},
} as ICspStudentSlice;

export const cspStudentSlice = createSlice({
  name: `cspStudentSlice`,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addMatcher(
      studentApi.endpoints.getCSPStudentData.matchFulfilled,
      (state, { payload }) => {
        state.studentData = payload;
      },
    );
  },
});

export const selectCspStudentData = (store: RootState) =>
  store.cspStudentDataSlice.studentData?.data;

export const selectCspStudentEditProfileData = (store: RootState) =>
  store.cspStudentDataSlice.studentData?.data?.personalProfile?.editProfile;

export default cspStudentSlice.reducer;
