import { useAppDispatch } from 'app/hooks';

import {
  ModalDataType,
  removeModal,
  updateModal,
} from '@/app/features/modals/modalsSlice';

import { MODAL_TYPES } from './GlobalModal';

/**
 * Hook for controlling Global Modals.
 */
export default function useModal() {
  const handleLogModalActionsForDebugging = ({
    modalProps,
    modalType,
    action,
  }: {
    modalType?: ModalDataType['modalType'] | null;
    action: 'SHOW' | 'UPDATE' | 'HIDE';
    modalProps?: ModalDataType['modalProps'] | null | undefined;
  }) => {
    const logMessage =
      action === `SHOW`
        ? `Triggered show modal with props`
        : action === `HIDE`
        ? `Triggered hide modal with props`
        : `Triggered update modal with props`;
    let stringifiedData = ``;
    try {
      stringifiedData = JSON.stringify(modalProps ?? ``);
    } catch (error) {
      console.log(`Error in stringifying modal props`, error, modalProps);
    }
  };

  const dispatch = useAppDispatch();
  const showModal = ({ modalProps, modalType, ...rest }: ModalDataType) => {
    handleLogModalActionsForDebugging({
      modalProps: modalProps,
      modalType: modalType,
      action: `SHOW`,
    });
    dispatch(
      updateModal({
        modalType,
        modalProps,
      } as ModalDataType),
    );
  };

  const updateModalReducerData = ({
    modalProps,
    modalType,
    ...rest
  }: ModalDataType) => {
    handleLogModalActionsForDebugging({
      modalProps,
      modalType,
      action: `UPDATE`,
    });
    dispatch(
      updateModal({
        modalType,
        modalProps,
      } as ModalDataType),
    );
  };

  const hideModal = (modalType?: MODAL_TYPES) => {
    handleLogModalActionsForDebugging({
      modalType,
      action: `HIDE`,
    });
    dispatch(removeModal(modalType));
  };

  return {
    showModal,
    hideModal,
    updateModalReducerData,
  };
}
