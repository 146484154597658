export enum TaskType {
  ADDITIONAL_DOCUMENTS_REQUIRED = `Additional Documents Required`,
  ADDITIONAL_APPLICATION_DETAILS_REQUIRED = `Additional application details required`,
  NEED_OFFER_CONFIRMATION = `Need Offer Confirmation`,
  PAYMENT_RELATED_ACITIONS_REQUIRED = `Payment Related Actions Required`,
  UNIVERSITY_FOLLOW_UP_NEEDED = `university Follow Up Needed`,
  APPLICATION_ON_HOLD = `Application On Hold`,
  ADDITIONAL_INFORMATION_REQUIRED = `Additional Information Required`,
  STUDENT_REFERENCE_ID = `Details Required : Student Reference needed`,
  STUDENT_PORTAL_LOGIN_CREDENTIALS = `Details Required : Student Portal Login Credentials needed`,
  STATUS_UPDATE_FROM_CSP = `Status Update from CSP Needed`,
  PORTAL_CHECKING = `Portal Checking Task for Updates`,
  OFFER_FOLLOW_UP_WITH_UNIVERSITY = `Offer Follow Up with University`,
  COUNSELLOR_PAYMENT_VERIFICATION = `Counsellor Payment Verification`,
  HCC_PAYMENT_VERIFICATION = `HCC Payment Verification`,
  CONDITION_MET_FOLLOW_UP = `Condition Met Follow up`,
  PAYMENT_CHASE = `Payment Chase`,
  STUDENT_SHORTLIST_CREATION = `Student Shortlist Creation`,
  OFFER_FOLLOW_UP_FOR_CA = `Offer Follow-up for Conditional Admit`,
  UNIVERSITY_DETAILS_VERIFICATION = `University Details Verification`,
  UNIVERSITY_EMAIL = `University Email`,
  JERRY_MISCALL_TASK = `Jerry Misscall Task`,
  RAISE_GIC = `Raise Gic`,
  CSP_ONBOARDING = `CSP_ONBOARDING`,
  APPLICATION_MAPPING_UPDATION_PORTAL_CHECKING = `Application Mapping Updation Portal Checking `,
  REVIEW_VISA_STATUS_BY_CSP = `REVIEW_VISA_STATUS_BY_CSP`,
}

export enum TaskPriority {
  HIGH = `HIGH`,
  MEDIUM = `MEDIUM`,
  LOW = `LOW`,
}

export enum TaskStatus {
  CANNOT_BE_PROCESSED = `CANNOT_BE_PROCESSED`,
  DONE = `DONE`,
  ON_HOLD = `ON_HOLD`,
  PENDING = `PENDING`,
  RESOLVED = `RESOLVED`,
  REOPENED = `REOPENED`,
  WORK_IN_PROGRESS = `WORK_IN_PROGRESS`,
}

export enum TaskEntity {
  APPLICATION = `APPLICATION`,
  UNIVERSITY = `UNIVERSITY`,
  STUDENT = `STUDENT`,
  CSP = `CSP`,
}

export enum TaskCommentTeam {
  LEAP = `LEAP`,
  GEEBEE = `GEEBEE`,
  CSP = `CSP`,
  GB_SYSTEM = `GB_SYSTEM`,
}

export enum BulkActionOptions {
  Download = `Download`,
}
