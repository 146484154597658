import { FetchArgs } from '@reduxjs/toolkit/query';
import { geebeeRootBaseApiWithTags } from 'services/base/geebeeApi';
import { paths } from 'types/schema';

import {
  CreateAddAndInitiateJerryCallRequestType,
  CreateAddAndInitiateJerryCallResponseType,
  CreateInitiateJerryCallRequestType,
  CreateInitiateJerryCallResponseType,
  CreateQuackerContactRequestType,
  CreateQuackerContactResponseType,
  GetAllQuackerContactsRequestType,
  GetAllQuackerContactsResponseType,
  GetQuackerContactsForCspRequestType,
  GetQuackerContactsForCspResponseType,
  QuackerContactLabelListRequestType,
  QuackerContactLabelListResponseType,
  QuackerContactTypeListRequestType,
  QuackerContactTypeListResponseType,
  QuackMobileDuplicatePathRequestType,
  QuackMobileDuplicatePathResponseType,
} from '@/types/Services';
import { transformResponse } from '@/utils/common';

export type AddOperatorResponseType =
  paths['/quacker/add-operator/{userId}']['post']['responses']['200']['schema'];
export type AddOperatorRequestType =
  paths['/quacker/add-operator/{userId}']['post']['parameters']['path'];
export type GetAllOperatorsResponseType =
  paths['/quacker/operators']['get']['responses']['200']['schema'];
export type CopyQuackerContactsResponseType =
  paths['/quacker/copy-contacts']['post']['responses']['200']['schema'];
export type CopyQuackerContactsRequestType =
  paths['/quacker/copy-contacts']['post']['parameters']['body']['dto'];

export type SyncQuackerContactsRequestType =
  paths[`/quacker/sync-contacts/{userId}`][`post`][`parameters`][`path`];
export type SyncQuackerContactsResponseType =
  paths[`/quacker/sync-contacts/{userId}`][`post`][`responses`][`200`][`schema`];

export const quackerApi = geebeeRootBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    createQuackerContact: builder.mutation<
      CreateQuackerContactResponseType,
      CreateQuackerContactRequestType
    >({
      query: (body) => {
        return {
          url: `/quacker/create-contact`,
          body,
          method: `POST`,
        } as FetchArgs;
      },
      transformResponse,
    }),
    getQuackerLabelOptions: builder.mutation<
      QuackerContactLabelListResponseType,
      QuackerContactLabelListRequestType
    >({
      query: (body) => ({
        url: `/quacker/label-options`,
        body,
        method: `POST`,
      }),
      transformResponse,
    }),
    getQuackerTypeOptions: builder.query<
      QuackerContactTypeListResponseType,
      QuackerContactTypeListRequestType
    >({
      query: () => `/quacker/type-options`,
      transformResponse,
    }),
    checkDuplicateQuackerContact: builder.query<
      QuackMobileDuplicatePathResponseType,
      QuackMobileDuplicatePathRequestType
    >({
      query: ({ contactPhoneNumber }) =>
        `/quacker/check-duplicate/${contactPhoneNumber}`,
      transformResponse,
      providesTags: [`QuackerCheckDuplicate`],
      keepUnusedDataFor: 0,
    }),
    getAllQuackerContacts: builder.mutation<
      GetAllQuackerContactsResponseType,
      GetAllQuackerContactsRequestType
    >({
      query: (body) => {
        return {
          url: `/quacker/contacts`,
          body,
          method: `POST`,
        } as FetchArgs;
      },
      transformResponse,
    }),
    getQuackerContactsForCsp: builder.mutation<
      GetQuackerContactsForCspResponseType,
      GetQuackerContactsForCspRequestType
    >({
      query: ({ cspId, ...body }) => {
        return {
          url: `/quacker/contacts/csp/${cspId}`,
          body,
          method: `POST`,
        } as FetchArgs;
      },
      transformResponse,
    }),
    createInitiateCall: builder.mutation<
      CreateInitiateJerryCallResponseType,
      CreateInitiateJerryCallRequestType
    >({
      query: (body) => {
        return {
          url: `/quacker/initiate-call`,
          body,
          method: `POST`,
        } as FetchArgs;
      },
      transformResponse,
    }),
    createAddAndInitiateCall: builder.mutation<
      CreateAddAndInitiateJerryCallResponseType,
      CreateAddAndInitiateJerryCallRequestType
    >({
      query: (body) => {
        return {
          url: `/quacker/add-and-initiate-call`,
          body,
          method: `POST`,
        } as FetchArgs;
      },
      transformResponse,
    }),
    addOperator: builder.mutation<
      AddOperatorResponseType,
      AddOperatorRequestType
    >({
      query: ({ userId }) => ({
        url: `/quacker/add-operator/${userId}`,
        method: `POST`,
      }),
      transformResponse: transformResponse,
      invalidatesTags: [`editUserInfo`],
    }),
    getAllOperators: builder.query<GetAllOperatorsResponseType, void>({
      query: () => `/quacker/operators`,
      transformResponse: transformResponse,
    }),
    copyContacts: builder.mutation<
      CopyQuackerContactsResponseType,
      CopyQuackerContactsRequestType
    >({
      query: (payload) => ({
        url: `/quacker/copy-contacts`,
        body: payload,
        method: `POST`,
      }),
      transformResponse: transformResponse,
    }),
    syncQuackerContacts: builder.mutation<
      SyncQuackerContactsResponseType,
      SyncQuackerContactsRequestType
    >({
      query: ({ userId }) => ({
        url: `/quacker/sync-contacts/${userId}`,
        method: `POST`,
      }),
      transformResponse: transformResponse,
    }),
  }),
});

export const {
  useCreateQuackerContactMutation,
  useGetQuackerLabelOptionsMutation,
  useGetQuackerTypeOptionsQuery,
  useLazyCheckDuplicateQuackerContactQuery,
  useGetAllQuackerContactsMutation,
  useGetQuackerContactsForCspMutation,
  useCreateInitiateCallMutation,
  useCreateAddAndInitiateCallMutation,
  useAddOperatorMutation,
  useGetAllOperatorsQuery,
  useCopyContactsMutation,
  useSyncQuackerContactsMutation,
} = quackerApi;
