import {
  GBModalWrapper,
  GBTypography,
} from '@leapfinance/geebee-component-library';
import { Close } from '@mui/icons-material';
import ReportIcon from '@mui/icons-material/Report';
import { Dialog } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../app/hooks';
import { close, CONFIRM_MODAL_TYPES } from '../features/confirmDialogSlice';

import CustomButton from './CustomMUIComponents/CustomButton';
import CommissionWarningModal from './dialogs/CommissionWarningModal';

const ConfirmDialog = () => {
  const { onSubmit, message, title, type, onCancel, options } = useAppSelector(
    (state) => state.confirmDialog,
  );
  const dispatch = useAppDispatch();

  if (type === CONFIRM_MODAL_TYPES.COMMISSION_WARNING) {
    return <CommissionWarningModal />;
  }

  return (
    <Dialog open={Boolean(onSubmit)} maxWidth="sm">
      <GBModalWrapper
        variant="small"
        headerProps={{
          title,
          onClose: () => {
            if (onCancel) {
              onCancel();
            }
            dispatch(close());
          },
        }}
        footerProps={{
          primaryCta: {
            title: options?.proceedButtonText ?? `Confirm`,
            buttonProps: {
              onClick: () => {
                if (onSubmit) {
                  onSubmit();
                }
                dispatch(close());
              },
              loading: options?.proceedButtonLoading,
            },
          },
          secondaryCta: {
            title: options?.cancelButtontext || `Cancel`,
            buttonProps: {
              onClick: () => {
                if (onCancel) {
                  onCancel();
                }
                dispatch(close());
              },
            },
          },
        }}
      >
        <div className="flex flex-col justify-center items-center p-4 gap-2 text-center">
          <ReportIcon className={`w-10 h-10 text-gbTokens-warning-500`} />
          {/* <GBTypography className="font-sans font-bold">{title}</DialogTitle> */}
          <GBTypography variant="body4">{message}</GBTypography>
        </div>
      </GBModalWrapper>
    </Dialog>
  );
};

export default ConfirmDialog;
