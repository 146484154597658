import { useContext } from 'react';
import { Button } from '@leapfinance/frontend-commons';
import GeebeeTheme from '@leapfinance/frontend-commons/components/geebee/theme';
import { Typography } from '@mui/material';
import { ThemeProvider } from '@mui/system';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';

import { useAppDispatch } from '@/app/hooks';
import { ApplicationContext } from '@/components/Application/context/Application';
import { MODAL_TYPES } from '@/components/modals/GlobalModal';
import useModal from '@/components/modals/useModal';
import { applicationApi } from '@/services/applicationApi';
import { useStartGteProcessMutation } from '@/services/gteServices';

const GTEModal = () => {
  const { hideModal } = useModal();
  const [triggerStartGteProcess] = useStartGteProcessMutation();
  const router = useRouter();
  // const { fetchStudentData } = useContext(ApplicationContext);

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  const handleStartGteProcess = async () => {
    const res = await triggerStartGteProcess({
      applicationId: router?.query?.slotId as unknown as number,
    }).unwrap();
    if (res?.success) {
      enqueueSnackbar(`GTE Process Successfully started for the application`, {
        variant: `success`,
      });
      setTimeout(() => {
        // fetchStudentData(router?.query?.slotId);
        dispatch(
          applicationApi.util.invalidateTags([`AllDocumentsForApplication`]),
        );
      }, 200);
    }
    hideModal(MODAL_TYPES.GTE_PROCESS);
  };

  return (
    <div className="w-full h-full flex justify-center items-center">
      <div className="bg-white rounded w-[440px]">
        <ThemeProvider theme={GeebeeTheme}>
          <div className="p-6">
            <div className="w-11 h-11 p-2.5 bg-error-100 rounded-full justify-start items-center gap-1 inline-flex">
              <Image
                src="/assets/icons/red-danger-icon.svg"
                alt="Warning"
                width={24}
                height={24}
              />
            </div>
            <Typography variant="h3" className="text-grey-900 mt-1">
              Start GTE Process
            </Typography>
            <Typography variant="body1" className="mt-1 text-grey-800">
              Are you sure, you want to start the GTE Process for the student?
            </Typography>
          </div>
          <div className="border-t border-grey-200 flex justify-between gap-x-2 bg-[#FAFAFA] px-6 py-3">
            <Button
              variant="outlined"
              className="w-1/2 normal-case py-2 px-4 bg-white border border-grey-400"
              onClick={() => hideModal(MODAL_TYPES.GTE_PROCESS)}
            >
              <Typography variant="subtitle2" className="text-grey-800 my-2">
                Cancel
              </Typography>
            </Button>
            <Button
              className="w-1/2 whitespace-nowrap normal-case"
              onClick={handleStartGteProcess}
            >
              <Typography variant="subtitle2" className="my-2">
                Start Process
              </Typography>
            </Button>
          </div>
        </ThemeProvider>
      </div>
    </div>
  );
};

export default GTEModal;
