/* eslint-disable @typescript-eslint/ban-types */
import React, {
  createContext,
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'app/hooks';
import { documentTypeOptions, OptionType } from 'lib/constant';
import { useRouter } from 'next/router';

import { initializeAttachedDocuments } from '@/features/miscellaneousDocSlice';
import useStateWithCallback from '@/hooks/useStateWithCallback';
import { isValidNumber } from '@/utils/common';

import { DeferralFormDataType } from '../../Deferral/DeferralProvider';
import {
  eachStudentApplicationData,
  fetchDocumentKeys,
  fetchUploadDocument,
} from '../ApplicationApis';
import { multiDocsTypes } from '../CommonFunctions';

import { Document, Documents, FormData, ProfileDataType } from './interface';

const initalFormData = {
  applicationDate: ``,
  annualTuitionFees: ``,
  applicationDropDate: ``,
  applicationFilerRemark: ``,
  applicationLink: ``,
  applicationSentDate: ``,
  applicationStageReason: ``,
  applicationType: ``,
  availablePriorities: [],
  country: ``,
  courseStartDate: ``,
  documentVerificationDate: ``,
  dueDate: ``,
  dueDateOffer: ``,
  feesPaid: ``,
  feesWith: ``,
  filingOwnerName: ``,
  followUpDate: ``,
  id: null,
  intake: ``,
  pathwayProvider: ``,
  cmsUniversityNames: ``,
  cmsUniversityName: ``,
  cmsUniversityId: ``,
  lastDateFeePayment: ``,
  lastDateOfferAcceptance: ``,
  onHoldMissingDocuments: null,
  onHoldIncorrectDocuments: null,
  onHoldUniversityMissingDocuments: null,
  onHoldUniversityIncorrectDocuments: null,
  incorrectDocuments: null,
  number: null,
  password: ``,
  paymentProofReceivedDate: ``,
  paymentRemarks: ``,
  paymentStatus: ``,
  paymentType: ``,
  programName: ``,
  priority: null,
  isPriorityChangeAllowed: null,
  reasonForDrop: ``,
  reasonForHold: ``,
  reasonForHoldExplanation: ``,
  reasonForHoldUniversity: ``,
  reasonForHoldUniversityExplanation: ``,
  reasonForRejection: ``,
  scholarApplicationId: null,
  stage: ``,
  studentReferenceId: null,
  universityName: ``,
  universityReferenceId: ``,
  username: ``,
  remarks: [],
  cspName: null,
  cspId: null,
  student: {
    id: null,
    scholarStudentId: null,
  },
  studentExam: {
    examType: ``,
    id: null,
    listeningScore: null,
    readingScore: null,
    scholarApplicationId: null,
    speakingScore: null,
    totalScore: null,
    writingScore: null,
  },
  tuitionFeeCurrency: null,
  opsManagerId: null,
  visaDetails: {
    country: null,
    gbVisaCounsellor: null,
    gbVisaCounsellorId: null,
    id: null,
    interviewDate: null,
    leapVisaCounsellor: null,
    scholarStudentId: null,
    scholarVisaId: null,
    universityName: null,
    visaApplicationStatus: null,
    visaApplicationSubStatus: null,
    visaDocumentStatus: null,
    studentId: null,
    applicationId: null,
    isArchived: false,
  },
  visaDetailsOtherApp: {
    country: null,
    gbVisaCounsellor: null,
    gbVisaCounsellorId: null,
    id: null,
    interviewDate: null,
    leapVisaCounsellor: null,
    scholarStudentId: null,
    scholarVisaId: null,
    universityName: null,
    visaApplicationStatus: null,
    visaApplicationSubStatus: null,
    visaDocumentStatus: null,
    studentId: null,
    applicationId: null,
    isArchived: false,
  },
  visaDocumentStatus: null,
  visaDocumentTypes: null,
  visaDocOnholdReasonExplanation: null,
  visaDocReviewStatus: null,
  conditionalReasonCleared: null,
  conditionalAdmitReasonList: null,
  counsellorName: null,
  conditionStatus: null,
  conditionalReason: null,
  admitDate: ``,
  conditionFollowUpDate: ``,
  conditionMetDate: ``,
  nextValidStates: [],
  deferralRequest: {} as DeferralFormDataType,
  canDeferralBeRaised: false,
  paymentVerificationStatus: null,
  reasonForOnHoldResolvedPaymentVerification: null,
  fullOrPartialPayment: null,
  nextValidPaymentVerificationStates: [],
  paymentVerificationStatusBar: [],
  followUpDateForCASORI20: null,
  reasonForOnHoldPaymentVerification: null,
  opsManagerName: ``,
  cspSecondaryEmails: ``,
  qcTeamUserName: ``,
  isOnshore: false,
  qcTeamUserId: null,
  documentVerifiedSubStatus: null,
  universityCommunicationPendingSubStatus: null,
  documentVerifiedSubStatusOpenDate: null,
  documentVerifiedSubStatusRemarks: null,
  universityCommunicationPendingSubStatusRemarks: null,
  assignedToId: null,
  assignedToName: ``,
  searchByKey: null,
  counsellorEmail: null,
  paymentDetails: null,
  eligibleDocumentKeysForOnHold: null,
  degreeId: null,
  degreeName: null,
  courseId: null,
  courseLink: null,
  counsellorNameContactNumber: null,
};

interface ApplicationProviderProps {
  children: React.ReactNode;
}

interface CustomRemarks {
  applicationId?: number;
  date?: string;
  givenBy?: string | null;
  id?: number | null;
  remark?: string;
}
export interface ConditionsReasonsType {
  condition: string;
  conditionDescription: string | null;
  conditionId: number;
  studentId: number;
  applicationId: number;
  conditionStatus: string | null;
  rejectReason: string | null;
  identifier: number;
}
interface ApplicationContextInterface {
  // isDownloadAllEnabled: boolean;
  // documents: Documents;
  formData: FormData;
  fetchStudentData: Function;
  statusOptions: Array<OptionType>;
  // documentOptions: Array<OptionType>;
  // documentsList: Array<Document>;
  // fetchDocuments: Function;
  appId: number | null;
  setFormData: Function;
  fetchStudentDataFailed: boolean;
  // documentKeys: { label: string; value: string }[];
  // fetchDocumentKeysList: Function;
  conditionalAdmitReasonsData: ConditionsReasonsType[] | [];
  documentAttachedWithInstallment: any[];
  setDocumentAttachedWithInstallment: Function;
}

export const ApplicationContext = createContext<ApplicationContextInterface>({
  // isDownloadAllEnabled: true,
  // documents: {},
  formData: initalFormData,
  fetchStudentData: (id: number) => {},
  statusOptions: [],
  // documentOptions: [],
  // documentsList: [],
  appId: null,
  setFormData: () => {},
  fetchStudentDataFailed: false,
  // fetchDocuments: (
  //   studentId: number | null,
  //   applicationId: number | null,
  // ) => {},
  // documentKeys: [],
  // fetchDocumentKeysList: () => {},
  conditionalAdmitReasonsData: [],
  documentAttachedWithInstallment: [],
  setDocumentAttachedWithInstallment: () => {},
});

const ApplicationProvider: FunctionComponent<ApplicationProviderProps> = (
  props,
) => {
  const router = useRouter();
  const { children } = props;
  const dispatch = useAppDispatch();
  // const [documents, setDocuments] = useState<Documents>({});
  const [documentAttachedWithInstallment, setDocumentAttachedWithInstallment] =
    useState<any[]>([]);
  // const [isDownloadAllEnabled, setIsDownloadAllEnabled] = useState(true);
  const [formData, setFormData] = useStateWithCallback<FormData>(
    initalFormData as any,
  );
  const [statusOptions, setStatusOptions] = useState<Array<OptionType>>([]);

  // const [documentOptions, setDocumentOptions] = useState<Array<OptionType>>([]);
  // const [documentsList, setDocumentsList] = useState<Array<Document>>([]);
  const [appId, setAppId] = useState<number | null>(null);
  // const [documentKeys, setDocumentKeys] = useState<
  //   { label: string; value: string }[]
  // >([]);
  const [conditionalAdmitReasonsData, setConditionalAdmitReasonsData] =
    useState<ConditionsReasonsType[]>([]);

  const [fetchStudentDataFailed, setFetchStudentDataFailed] =
    useState<boolean>(false);
  // useEffect(() => {
  //   if (Object.keys(documents).length) updateDocsOptionsAndDocuments();
  // }, [documents]);

  const fetchStudentData = async (id: number, successCallback?: () => void) => {
    setFetchStudentDataFailed(false);
    if (!isValidNumber(id)) {
      setFetchStudentDataFailed(true);
      return;
    }
    try {
      const res = await eachStudentApplicationData(id);

      if (!res.data.success)
        throw new Error(
          res.data.message ||
            `Something went wrong.Please contact the tech team`,
        );
      const data: FormData = res.data.data;
      dispatch(
        initializeAttachedDocuments(
          data.paymentDetails?.transactions?.map((item) => {
            return {
              transactionId: item.id,
              document: item.paymentProofDocument,
            };
          }) || [],
        ),
      );
      setAppId(data?.id);
      const {
        id: applicationId,
        stage,
        scholarApplicationId,
        student: { scholarStudentId, id: studentId },
      } = data;

      const conditionalInfo: ConditionsReasonsType[] = [];
      data?.conditionalAdmitReasonList?.map((item: any, index: number) =>
        conditionalInfo.push({
          condition: item.condition,
          conditionDescription: item.conditionDescription,
          conditionId: item.conditionId,
          studentId: item.studentId,
          applicationId: item.applicationId,
          conditionStatus: item.conditionStatus,
          rejectReason: item.rejectReason,
          identifier: index,
        }),
      );
      const payload: string[] = [];
      conditionalInfo.forEach((item: any) => payload.push(item.condition));

      setConditionalAdmitReasonsData(conditionalInfo);
      // fetchDocuments(studentId, applicationId);
      getStateValues(stage, data.nextValidStates);
      if (payload.length > 0) {
        data.conditionalReason = payload;
      }
      setFormData(data, () => {
        if (successCallback) {
          successCallback();
        }
      });
      return data;
    } catch (error: any) {
      setFetchStudentDataFailed(true);
      toast.error(error.message);
    }
  };

  // const fetchDocuments = async (
  //   studentId: number | null,
  //   applicationId: number | null,
  // ) => {
  //   try {
  //     const res = await fetchUploadDocument({
  //       studentId: studentId,
  //       applicationId: applicationId,
  //     });
  //     if (!res.success) throw Error(``);
  //     setDocuments(res.data.documentStatus);
  //     setIsDownloadAllEnabled(res.data.isDownloadAllEnabled);
  //   } catch (error) {
  //     toast.error(`Something went wrong.Please contact the tech team`);
  //   }
  // };
  let options: any;
  const getStateValues = (
    stage: string,
    nextValidStates: Array<{ label: string; labelKey: string }> = [],
  ) => {
    const stageLabel = stage
      .split(`_`)
      .map((word: string) => {
        return `${word.substring(0, 1)}${word
          .substring(1)
          .toLocaleLowerCase()}`;
      })
      .join(` `);
    options = nextValidStates?.map((elem) => ({
      label: elem?.label,
      value: elem.labelKey,
    }));
    setStatusOptions([{ label: stageLabel, value: stage }, ...options]);
  };

  // const fetchDocumentKeysList = async () => {
  //   try {
  //     const res = await fetchDocumentKeys();
  //     if (res && res?.length > 0) {
  //       setDocumentKeys(res);
  //     }
  //   } catch {
  //     toast.error(`Something went wrong.Please contact tech team`);
  //   }
  // };

  // const updateDocsOptionsAndDocuments = () => {
  //   let dataKeys = Object.keys(documents);
  //   dataKeys = dataKeys?.filter((elem: any) => !multiDocsTypes.includes(elem));
  //   const newMiscOptions = documentTypeOptions?.filter(
  //     (item: any) => !dataKeys.includes(item.value),
  //   );
  //   setDocumentOptions(newMiscOptions);
  //   //removing the ulimited keys condition as a hot fix
  //   const docs = Object.keys(documents)?.map((key: any) => documents[key]);
  //   if (docs?.length > 0) {
  //     const singleArrDocs = docs?.reduce((ele1: any, ele2: any) =>
  //       ele1.concat(ele2),
  //     );
  //     setDocumentsList(singleArrDocs);
  //   } else {
  //     setDocumentsList([]);
  //   }
  // };

  return (
    <ApplicationContext.Provider
      value={{
        // isDownloadAllEnabled,
        // documents,
        formData,
        fetchStudentData,
        statusOptions,
        // documentOptions,
        // documentsList,
        // fetchDocuments,
        appId,
        setFormData,
        fetchStudentDataFailed,
        // documentKeys,
        // fetchDocumentKeysList,
        conditionalAdmitReasonsData,
        documentAttachedWithInstallment,
        setDocumentAttachedWithInstallment,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};

export default ApplicationProvider;
