import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const geebeeBaseApi = createApi({
  reducerPath: `geebeeBaseApi`,
  baseQuery: fetchBaseQuery({ baseUrl: `/api/geebeeRoute?path=` }),
  endpoints: () => ({}),
});

export const geebeeRootBaseApiWithTags = geebeeBaseApi.enhanceEndpoints({
  addTagTypes: [
    `editUserInfo`,
    `updateCspStudentInfo`,
    `Deferral`,
    `AuditLogs`,
    `StudentShortLists`,
    `Shortlist`,
    `getCSPStudentDocuments`,
    `applicationDocuments`,
    `documents`,
    `Student`,
    `RequiredDocs`,
    `preVisaStatus`,
    `SingleCspUser`,
    `OpsUrgentAction`,
    `OpsStageGroup`,
    `getCspStudent`,
    `StudentEducationForm`,
    `StudentWorkForm`,
    `StudentExamForm`,
    `GteApplicationDocuments`,
    `GteConditionUpdate`,
    `DataCorrectionGetEligibilityParameter`,
    `DataCorrectionGetRequiredParameter`,
    `DataCorrectionReportIssues`,
    `UniversityPage`,
    `AccomodationRequest`,
    `GICRequest`,
    `FlywireRequest`,
    `CountryAndState`,
    `GetStudentPersonalInfo`,
    `GetStudentAcademicInfo`,
    `GetStudentExamInfo`,
    `GetStudentWorkInfo`,
    `CountryData`,
    `CountryAndStateAndCity`,
    `QuackerCheckDuplicate`,
    `validateExamRemoval`,
    `StudentOnboardingForm`,
    `GetRecommendedShortlistFeedback`,
    `ShortlistRequest`,
    `CSPCounsellorNotes`,
    `StudentOnboardingFormPreference`,
    `GetSopDetailsFromAppId`,
    `GetSopDetailsFromRequestId`,
    `GetAllSopRequests`,
    `GICRequestRaised`,
    `GetSopQcPlagiarismDetails`,
    `LoansRequest`,
    `CmsCountries`,
    `CmsStates`,
    `GetCspMilestoneList`,
    `MileStone`,
    `CallingTargets`,
    `AvailableUserTypes`,
    `CohortCallingDetails`,
    `PostQcDetails`,
    `AllDocumentsForApplication`,
    `GetSingleApplicationData`,
    `CspAccountInfo`,
  ],
});
