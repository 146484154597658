import { OptionType } from '@leapfinance/geebee-component-library';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';

import { multiDocsTypes } from '@/components/Application/CommonFunctions';
import { ConditionsReasonsType } from '@/components/Application/context/Application';
import { DeferralFormDataType } from '@/components/Deferral/DeferralProvider';
import { documentTypeOptions } from '@/lib/constant';
import { applicationApi } from '@/services/applicationApi';
import { documentApi } from '@/services/documents';
import { definitions } from '@/types/schema';
import { GetApplicationResponseType } from '@/types/service';
import { GetAllDocumentsForApplicationResponseType } from '@/types/Services';

export type ApplicationDetailsDocumentType = Exclude<
  GetAllDocumentsForApplicationResponseType['data'],
  undefined
>['documentStatus'];

export type FormDataType = GetApplicationResponseType['data'];

const initialFormData = {
  applicationDate: ``,
  annualTuitionFees: ``,
  applicationDropDate: ``,
  applicationFilerRemark: ``,
  applicationLink: ``,
  applicationSentDate: ``,
  applicationStageReason: ``,
  applicationType: ``,
  availablePriorities: [],
  country: ``,
  courseStartDate: ``,
  documentVerificationDate: ``,
  dueDate: ``,
  dueDateOffer: ``,
  feesPaid: ``,
  feesWith: ``,
  filingOwnerName: ``,
  followUpDate: ``,
  id: null,
  intake: ``,
  pathwayProvider: ``,
  cmsUniversityNames: ``,
  cmsUniversityName: ``,
  cmsUniversityId: ``,
  lastDateFeePayment: ``,
  lastDateOfferAcceptance: ``,
  onHoldMissingDocuments: null,
  onHoldIncorrectDocuments: null,
  onHoldUniversityMissingDocuments: null,
  onHoldUniversityIncorrectDocuments: null,
  incorrectDocuments: null,
  number: null,
  password: ``,
  paymentProofReceivedDate: ``,
  paymentRemarks: ``,
  paymentStatus: ``,
  paymentType: ``,
  programName: ``,
  priority: null,
  isPriorityChangeAllowed: null,
  reasonForDrop: ``,
  reasonForHold: ``,
  reasonForHoldExplanation: ``,
  reasonForHoldUniversity: ``,
  reasonForHoldUniversityExplanation: ``,
  reasonForRejection: ``,
  scholarApplicationId: null,
  stage: ``,
  studentReferenceId: null,
  universityName: ``,
  universityReferenceId: ``,
  username: ``,
  remarks: [],
  cspName: null,
  cspId: null,
  student: {
    id: null,
    scholarStudentId: null,
  },
  studentExam: {
    examType: ``,
    id: null,
    listeningScore: null,
    readingScore: null,
    scholarApplicationId: null,
    speakingScore: null,
    totalScore: null,
    writingScore: null,
  },
  tuitionFeeCurrency: null,
  opsManagerId: null,
  visaDetails: {
    country: null,
    gbVisaCounsellor: null,
    gbVisaCounsellorId: null,
    id: null,
    interviewDate: null,
    leapVisaCounsellor: null,
    scholarStudentId: null,
    scholarVisaId: null,
    universityName: null,
    visaApplicationStatus: null,
    visaApplicationSubStatus: null,
    visaDocumentStatus: null,
    studentId: null,
    applicationId: null,
    isArchived: false,
  },
  visaDetailsOtherApp: {
    country: null,
    gbVisaCounsellor: null,
    gbVisaCounsellorId: null,
    id: null,
    interviewDate: null,
    leapVisaCounsellor: null,
    scholarStudentId: null,
    scholarVisaId: null,
    universityName: null,
    visaApplicationStatus: null,
    visaApplicationSubStatus: null,
    visaDocumentStatus: null,
    studentId: null,
    applicationId: null,
    isArchived: false,
  },
  visaDocumentStatus: null,
  visaDocumentTypes: null,
  visaDocOnholdReasonExplanation: null,
  visaDocReviewStatus: null,
  conditionalReasonCleared: null,
  conditionalAdmitReasonList: null,
  counsellorName: null,
  conditionStatus: null,
  conditionalReason: null,
  admitDate: ``,
  conditionFollowUpDate: ``,
  conditionMetDate: ``,
  nextValidStates: [],
  deferralRequest: {} as DeferralFormDataType,
  canDeferralBeRaised: false,
  paymentVerificationStatus: null,
  reasonForOnHoldResolvedPaymentVerification: null,
  fullOrPartialPayment: null,
  nextValidPaymentVerificationStates: [],
  paymentVerificationStatusBar: [],
  followUpDateForCASORI20: null,
  reasonForOnHoldPaymentVerification: null,
  opsManagerName: ``,
  cspSecondaryEmails: ``,
  qcTeamUserName: ``,
  isOnshore: false,
  qcTeamUserId: null,
  documentVerifiedSubStatus: null,
  universityCommunicationPendingSubStatus: null,
  documentVerifiedSubStatusOpenDate: null,
  documentVerifiedSubStatusRemarks: null,
  universityCommunicationPendingSubStatusRemarks: null,
  assignedToId: null,
  assignedToName: ``,
  searchByKey: null,
  counsellorEmail: null,
  paymentDetails: null,
  eligibleDocumentKeysForOnHold: null,
  degreeId: null,
  degreeName: null,
  courseId: null,
  courseLink: null,
  counsellorNameContactNumber: null,
};

interface ApplicationDetailsState {
  documents: ApplicationDetailsDocumentType;
  isDownloadAllEnabled?: boolean;
  documentOptions?: Array<OptionType>;
  documentsList?: Array<ApplicationDetailsDocumentType>;
  documentKeys?: Array<OptionType>;
  fetchStudentDataFailed: boolean;
  statusOptions: Array<OptionType>;
  appId?: number;
  conditionalAdmitReasonsData: ConditionsReasonsType[];
  formData: FormDataType & {
    conditionalReason: string[];
  };
  originalFormData: FormDataType & {
    conditionalReason: string[];
  };
  documentsAttachedWithInstallment: Array<{
    transactionId: any;
    document: any;
  }>;
  alternateApplicationsOfStudent: definitions['SearchApplicationResponse']['applications'];
  //   formData: FormData;
}

const initialState: ApplicationDetailsState = {
  documents: {},
  documentsList: [],
  documentOptions: [],
  documentKeys: [],
  fetchStudentDataFailed: false,
  statusOptions: [],
  conditionalAdmitReasonsData: [],
  documentsAttachedWithInstallment: [],
  formData: initialFormData as any,
  originalFormData: initialFormData as any,
  //   isDownloadAllEnabled: undefined,
  //   formData: {},
  alternateApplicationsOfStudent: [],
};

export const applicationDetailsSlice = createSlice({
  name: `applicationDetails`,
  initialState,
  reducers: {
    updateDocuments: (
      state,
      action: PayloadAction<ApplicationDetailsDocumentType>,
    ) => {
      state.documents = action.payload;
    },
    attachDocumentWithInstallment: (
      state,
      action: PayloadAction<{
        transactionId: any;
        document: any;
      }>,
    ) => {
      //   state.documentsAttachedWithInstallment = [
      //     ...state.documentsAttachedWithInstallment,
      //     action.payload,
      //   ];

      // if some document already exists and we attach it
      if (
        state.documentsAttachedWithInstallment.some(
          (obj) => obj[`transactionId`] === action.payload.transactionId,
        )
      ) {
        const installmentWithoutRecentlyAttachedDoc =
          state.documentsAttachedWithInstallment.filter(
            (item) => item.transactionId !== action.payload.transactionId,
          );
        state.documentsAttachedWithInstallment = [
          ...installmentWithoutRecentlyAttachedDoc,
          action.payload,
        ];
      } else {
        state.documentsAttachedWithInstallment = [
          ...state.documentsAttachedWithInstallment,
          action.payload,
        ];
      }
    },
    detachDocumentWithInstallment: (
      state,
      action: PayloadAction<{
        transactionId: any;
        document: any;
      }>,
    ) => {
      state.documentsAttachedWithInstallment =
        state.documentsAttachedWithInstallment.filter(
          (item) => item.transactionId !== action.payload.transactionId,
        );
    },
    updateFormData: (state, action: PayloadAction<Record<string, any>>) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    resetFormData: (state) => {
      state.formData = state.originalFormData;
    },
    updateAlternateApplicationsOfStudent: (
      state,
      action: PayloadAction<
        definitions['SearchApplicationResponse']['applications']
      >,
    ) => {
      state.alternateApplicationsOfStudent = action?.payload;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      applicationApi.endpoints.getAllDocumentsForApplication.matchFulfilled,
      (_state, { payload }) => {
        _state.documents = payload?.data?.documentStatus ?? {};
        _state.isDownloadAllEnabled = payload?.data?.isDownloadAllEnabled;
        if (payload?.data?.documentStatus) {
          let dataKeys = Object.keys(payload?.data?.documentStatus);
          dataKeys = dataKeys?.filter(
            (elem: any) => !multiDocsTypes.includes(elem),
          );
          const newMiscOptions = documentTypeOptions?.filter(
            (item: any) => !dataKeys.includes(item.value),
          );
          _state.documentOptions = newMiscOptions;

          //removing the ulimited keys condition as a hot fix
          const docs = Object.keys(payload?.data?.documentStatus)?.map(
            (key: any) => payload?.data?.documentStatus[key],
          );
          if (docs?.length > 0) {
            const singleArrDocs = docs?.reduce((ele1: any, ele2: any) =>
              ele1.concat(ele2),
            );
            _state.documentsList = singleArrDocs as any;
          } else {
            _state.documentsList = [];
          }
        }
      },
    );
    builder.addMatcher(
      documentApi.endpoints.getDocumentKeys.matchFulfilled,
      (_state, { payload }) => {
        const keys =
          payload?.data?.map((item) => {
            return {
              label: item.split(`_`).join(` `),
              value: item,
            };
          }) ?? [];
        _state.documentKeys = keys;
      },
    );
    builder.addMatcher(
      applicationApi.endpoints.getSingleApplicationData.matchFulfilled,
      (_state, { payload }) => {
        //  const data: FormData = res.data.data;

        //   dispatch(
        //     initializeAttachedDocuments(
        //       data.paymentDetails?.transactions?.map((item) => {
        //         return {
        //           transactionId: item.id,
        //           document: item.paymentProofDocument,
        //         };
        //       }) || [],
        //     ),
        //   );
        (_state.documentsAttachedWithInstallment =
          payload?.data?.paymentDetails?.transactions?.map((item) => {
            return {
              transactionId: item.id,
              document: item.paymentProofDocument,
            };
          }) || []),
          //   setAppId(data?.id);
          (_state.appId = payload?.data?.id);
        //   const {
        //     student,
        //   } = payload?.data ?? {};
        //   const { scholarStudentId, id: studentId } = student ?? {};

        const conditionalInfo: ConditionsReasonsType[] = [];
        payload?.data?.conditionalAdmitReasonList?.map(
          (item: any, index: number) =>
            conditionalInfo.push({
              condition: item.condition,
              conditionDescription: item.conditionDescription,
              conditionId: item.conditionId,
              studentId: item.studentId,
              applicationId: item.applicationId,
              conditionStatus: item.conditionStatus,
              rejectReason: item.rejectReason,
              identifier: index,
            }),
        );
        //   setConditionalAdmitReasonsData(conditionalInfo);
        _state.conditionalAdmitReasonsData = conditionalInfo;

        //   if (_state.formData  && localConditionalInfos.length > 0) {
        //    _state.formData.conditionalReason = localConditionalInfos;
        //   }
        //   // fetchDocuments(studentId, applicationId);

        //   getStateValues(stage, data.nextValidStates);
        _state.statusOptions = getStateValues(
          payload?.data?.stage as any,
          payload?.data?.nextValidStates as any,
        );

        //   setFormData(data, () => {
        //     if (successCallback) {
        //       successCallback();
        //     }
        //   });
        const localConditionalInfos: string[] = [];
        conditionalInfo.forEach((item: any) =>
          localConditionalInfos.push(item.condition),
        );
        _state.formData = {
          ...payload?.data,
          conditionalReason:
            localConditionalInfos.length > 0 ? localConditionalInfos : [],
        } as any;
        _state.originalFormData = {
          ...payload?.data,
          conditionalReason:
            localConditionalInfos.length > 0 ? localConditionalInfos : [],
        } as any;
        //   return data;
      },
    ),
      builder.addMatcher(
        applicationApi.endpoints.getSingleApplicationData.matchRejected,
        (_state, { payload }) => {
          _state.fetchStudentDataFailed = true;
        },
      );
  },
});

function getStateValues(
  stage: string,
  nextValidStates: Array<{ label: string; labelKey: string }> = [],
) {
  const stageLabel = stage
    .split(`_`)
    .map((word: string) => {
      return `${word.substring(0, 1)}${word.substring(1).toLocaleLowerCase()}`;
    })
    .join(` `);
  const options = nextValidStates?.map((elem) => ({
    label: elem?.label,
    value: elem.labelKey,
  }));
  return [{ label: stageLabel, value: stage }, ...options];
}

export const {
  updateDocuments,
  attachDocumentWithInstallment,
  detachDocumentWithInstallment,
  updateFormData,
  resetFormData,
  updateAlternateApplicationsOfStudent,
} = applicationDetailsSlice.actions;

// export const selectFormData = (state: RootState) => state.applicationDetails.formData;
export const selectDocuments = (state: RootState) =>
  state.applicationDetails.documents;
export const selectIsDownloadAllEnabled = (state: RootState) =>
  state.applicationDetails.isDownloadAllEnabled;
export const selectDocumentOptions = (state: RootState) =>
  state.applicationDetails.documentOptions;
export const selectDocumentsList = (state: RootState) =>
  state.applicationDetails.documentsList;
export const selectDocumentKeys = (state: RootState) =>
  state.applicationDetails.documentKeys;
export const selectAttachedDocuments = (state: RootState) =>
  state.applicationDetails.documentsAttachedWithInstallment;
export const selectFormData = (state: RootState) =>
  state.applicationDetails.formData;
export const selectOriginalFormData = (state: RootState) =>
  state.applicationDetails.originalFormData;
export const selectFetchStudentDataFailed = (state: RootState) =>
  state.applicationDetails.fetchStudentDataFailed;
export const selectConditionalAdmitReasonsData = (state: RootState) =>
  state.applicationDetails.conditionalAdmitReasonsData;
export const selectStatusOptions = (state: RootState) =>
  state.applicationDetails.statusOptions;

export const selectAlternateApplicationsOfStudent = (state: RootState) =>
  state.applicationDetails.alternateApplicationsOfStudent;

export default applicationDetailsSlice.reducer;
