import { FetchArgs } from '@reduxjs/toolkit/dist/query';

import { documentDownloadBaseApi } from '@/services/base/documentDownload';
import { geebeeRootBaseApiWithTags } from '@/services/base/geebeeApi';
import { Document, DocumentSectionData } from '@/types/documentSection';
import {
  AttachDocumentResponseType,
  DetachDocumentResponseType,
  GetPresignedUrlRequestType,
  GetPresignedUrlResponseType,
  UploadDocumentRequestType,
  UploadDocumentResponseType,
} from '@/types/service';
import {
  DeleteDocumentRequestBodyType,
  DeleteDocumentResponseType,
  DocumentLayoutResponse,
  DocumentSaveResponse,
  DocumentViewResponse,
  GetApplicationRequiredDocumentListResponse,
  GetDocumentAdditionalInfoRequestType,
  GetDocumentAdditionalInfoResponseType,
  GetDocumentKeysResponseType,
  MarkDocumentAsUploadedResponseType,
  UpdateDocumentStatus,
  UploadDocumentEmailAttachmentRequestType,
  UploadDocumentEmailAttachmentResponseType,
  ValidateDocumentScoreRequestType,
  ValidateDocumentScoreResponseType,
} from '@/types/Services';

import { documentUploadBaseApi } from './base/documentUpload';
import { transformResponse } from './util';

export const documentApi = geebeeRootBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getDocuments: builder.query<DocumentSectionData[], number>({
      query: (id: number) => `/v1/documents/student/${id}`,
      transformResponse: (response: DocumentLayoutResponse) =>
        response.data.documents,
      providesTags: [`documents`],
    }),
    saveDocuments: builder.mutation<
      DocumentSaveResponse,
      {
        referenceLabel: string;
        referenceLabelId: number;
        documentGroup: string;
        documents: Document[];
        studentId: number;
      }
    >({
      query: ({
        referenceLabel,
        referenceLabelId,
        documentGroup,
        documents,
        studentId,
      }) => ({
        url: `v1/documents/doc/${referenceLabel}/${referenceLabelId}/${documentGroup}/student/${studentId}`,
        body: documents,
        method: `POST`,
      }),
      invalidatesTags: [`documents`],
    }),

    archiveDocument: builder.mutation<
      void,
      { documentId: number; keyName: string }
    >({
      query: ({ documentId, keyName }) => ({
        url: `v1/documents/${documentId}/${keyName}`,
        method: `DELETE`,
      }),
      invalidatesTags: [`documents`],
    }),

    deleteDocument: builder.mutation<
      void,
      { documentId: number; keyName: string }
    >({
      query: ({ documentId, keyName }) => ({
        url: `documents/${documentId}/${keyName}`,
        method: `DELETE`,
      }),
      invalidatesTags: [`documents`, `applicationDocuments`, `Student`],
    }),

    viewDocument: builder.query<
      DocumentViewResponse,
      { keyName: string; documentId: number }
    >({
      query: ({ keyName, documentId }) =>
        `v1/documents/${documentId}/${keyName}/view`,
    }),

    detachDocument: builder.mutation<
      DetachDocumentResponseType,
      { documentId: number; keyName: string }
    >({
      query: ({ documentId, keyName }) => {
        const formData = new FormData();

        formData.append(`documentId`, String(documentId));
        formData.append(`keyName`, String(keyName));

        return {
          url: `/documents/detach`,
          method: `POST`,
          body: formData,
        };
      },
    }),
    getDocumentKeys: builder.query<GetDocumentKeysResponseType, void>({
      query: () => `documents/keys`,
      //[Todo]: Throw error if success false and create a HOC or wrapper for transform response
      transformResponse,
    }),

    getApplicationsRequiredDocumentsList: builder.query<
      GetApplicationRequiredDocumentListResponse,
      {
        universityId: number;
        degreeId: number | null;
        studentId: number | null;
        applicationId?: number | null;
      }
    >({
      query: ({ universityId, degreeId, studentId, applicationId }) => ({
        url: `/university-required-info/required-documents/${universityId}/degree/${degreeId}/student/${studentId}`,
        params: { applicationId: applicationId },
      }),
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: undefined,
            message,
            apiError: false,
          } as unknown as GetApplicationRequiredDocumentListResponse;
        } else {
          return {
            data,
          } as unknown as GetApplicationRequiredDocumentListResponse;
        }
      },
    }),

    markDocumentAsUploaded: builder.mutation<
      MarkDocumentAsUploadedResponseType,
      {
        applicationId: number;
        documentId: number;
        documentKey: string;
        status: string;
        studentId: number;
      }
    >({
      query: ({
        applicationId,
        documentId,
        documentKey,
        status,
        studentId,
      }) => {
        const formData = new FormData();
        formData.append(`applicationId`, String(applicationId));
        formData.append(`documentId`, String(documentId));
        formData.append(`keyName`, String(documentKey));
        formData.append(`status`, String(status));
        formData.append(`studentId`, String(studentId));

        return {
          url: `/v1/documents/change-document-status`,
          method: `POST`,
          body: formData,
        };
      },
      invalidatesTags: [`RequiredDocs`],
    }),
    attachDocument: builder.mutation<
      AttachDocumentResponseType,
      {
        documentId: number;
        referenceLabel: string;
        referenceLabelId: number;
        keyName: string;
      }
    >({
      query: ({ keyName, documentId, referenceLabel, referenceLabelId }) => {
        const formData = new FormData();
        formData.append(`referenceLabel`, String(referenceLabel));
        formData.append(`documentId`, String(documentId));
        formData.append(`keyName`, String(keyName));
        formData.append(`referenceLabelId`, String(referenceLabelId));

        return {
          url: `/documents/attach`,
          method: `POST`,
          body: formData,
        };
      },
    }),
    updateDocumentStatus: builder.mutation<void, UpdateDocumentStatus>({
      query: ({
        applicationId,
        keyName,
        documentId,
        documentStatus,
        rejectReason,
      }) => {
        return {
          url: `documents/status`,
          method: `POST`,
          body: {
            applicationId,
            documentId,
            keyName,
            documentStatus,
            rejectReason,
          },
        };
      },
      invalidatesTags: [`documents`, `applicationDocuments`],
    }),
    getPresignedUrl: builder.mutation<
      GetPresignedUrlResponseType,
      GetPresignedUrlRequestType
    >({
      query: ({ url }) => {
        return {
          url: `/documents/pre-signed-url`,
          method: `POST`,
          body: { url },
        };
      },
    }),

    validateDocumentScore: builder.mutation<
      ValidateDocumentScoreResponseType,
      ValidateDocumentScoreRequestType
    >({
      query: (body) => {
        const request: FetchArgs = {
          url: `documents/validate-score`,
          body,
          method: `POST`,
        };

        return request;
      },
      transformResponse: transformResponse,
    }),

    getAdditionalInfo: builder.mutation<
      GetDocumentAdditionalInfoResponseType,
      GetDocumentAdditionalInfoRequestType
    >({
      query: (body) => {
        const request: FetchArgs = {
          url: `/documents/additional-information`,
          method: `POST`,
          body,
        };
        return request;
      },
      transformResponse: transformResponse,
    }),

    deleteDocumentFromAWS: builder.mutation<
      DeleteDocumentResponseType,
      DeleteDocumentRequestBodyType
    >({
      query: (body) => {
        const request: FetchArgs = {
          url: `/documents/delete`,
          method: `POST`,
          body,
        };
        return request;
      },
      transformResponse: transformResponse,
    }),
  }),
});

const downloadDocumentApi = documentDownloadBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    downloadDocument: builder.query<
      void,
      { keyName: string; documentId: number }
    >({
      query: ({ keyName, documentId }) =>
        `/documents/download/${keyName}/${documentId}`,
      transformResponse: (response: any) => response.data,
    }),
    downloadRemarkDocument: builder.query<void, number>({
      query: (remarkId) => `/remarks/${remarkId}/download-attachment`,
      transformResponse: (response: any) => response.data,
    }),
  }),
});
const uploadDocumentApi = documentUploadBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    uploadDocument: builder.query<
      void,
      { keyName: string; documentId: number; entityId?: number; file?: File }
    >({
      query: ({ keyName, documentId, entityId, file }) => {
        if (!file) return ``;
        const queryObject: any = {
          url: `documents/doc/${keyName}/${documentId}/${entityId}`,
          method: `POST`,
        };

        const formData = new FormData();

        if (file) {
          formData.append(`file`, file);
        }
        queryObject[`body`] = formData;

        return queryObject;
      },
      transformResponse: (response: any) => response.data,
    }),
    uploadStudentDocument: builder.mutation<
      UploadDocumentResponseType,
      UploadDocumentRequestType
    >({
      query: ({ file, keyName, studentId, applicationId }) => {
        const formData = new FormData();

        formData.append(`file`, file as File);
        formData.append(`keyName`, keyName);
        formData.append(`studentId`, String(studentId));
        if (applicationId) {
          formData.append(`applicationId`, String(applicationId));
        }
        const request: FetchArgs = {
          url: `/documents/upload`,
          body: formData,
          method: `POST`,
        };

        return request;
      },
    }),

    uploadDocumentEmailAttachment: builder.mutation<
      UploadDocumentEmailAttachmentResponseType,
      UploadDocumentEmailAttachmentRequestType
    >({
      query: ({ file }) => {
        const formData = new FormData();

        formData.append(`file`, file as File);

        const request: FetchArgs = {
          url: `/documents/upload/email-attachment`,
          body: formData,
          method: `POST`,
        };

        return request;
      },
    }),
  }),
});

export const {
  useGetDocumentsQuery,
  useSaveDocumentsMutation,
  useArchiveDocumentMutation,
  useLazyViewDocumentQuery,
  useLazyGetDocumentsQuery,
  useDeleteDocumentMutation,
  useGetDocumentKeysQuery,
  useLazyGetApplicationsRequiredDocumentsListQuery,
  useGetApplicationsRequiredDocumentsListQuery,
  useMarkDocumentAsUploadedMutation,
  useAttachDocumentMutation,
  useDetachDocumentMutation,
  useUpdateDocumentStatusMutation,
  useGetPresignedUrlMutation,
  useValidateDocumentScoreMutation,
  useGetAdditionalInfoMutation,
  useDeleteDocumentFromAWSMutation,
} = documentApi;

export const {
  useDownloadDocumentQuery,
  useLazyDownloadDocumentQuery,
  useLazyDownloadRemarkDocumentQuery,
} = downloadDocumentApi;
export const {
  useLazyUploadDocumentQuery,
  useUploadStudentDocumentMutation,
  useUploadDocumentEmailAttachmentMutation,
} = uploadDocumentApi;
