import { applyMiddleware, configureStore } from '@reduxjs/toolkit';
import { composeWithDevTools } from '@reduxjs/toolkit/dist/devtoolsExtension';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { rootReducer } from 'redux/rootReducer';
import createSagaMiddleware from 'redux-saga';
import { geebeeBaseApi } from 'services/base/geebeeApi';
import { userManagmentApi } from 'services/userManagmentApi';

import userInfoListSlice from '@/app/features/userInfoList/userInfoListSlice';
import { universityApi } from '@/components/CF/University/universityService';
import { commissionApi } from '@/components/Commission/services/geebeeCommission';
import { commisionFileUploadApi } from '@/components/Commission/services/geebeeCommissionUpload';
import { cfStateSlice } from '@/features/courseFinder/cfStateSlice';
import { courseFinderSlice } from '@/features/courseFinder/courseFinderSlice';
import mappingSlice from '@/features/mapping/mappingSlice';
import miscDocSlice from '@/features/miscellaneousDocSlice';
import joyRideSlice from '@/features/react-joyride-product-tour/reactJoyrideProductTour';
import uploadDocuments from '@/features/uploadDocuments/uploadDocuments';
import { applicationQcApi } from '@/services/base/baseApplicationQc';
import { bookmarkListBaseApi } from '@/services/base/bookmarkListRoute';
import { cmsBaseApiWithTags } from '@/services/base/cmsRoute';
import { documentUploadBaseApi } from '@/services/base/documentUpload';
import { metabaseApiWithTags } from '@/services/base/metabaseAnalyticsRoute';
import { rulesBaseApiWithTags } from '@/services/base/rulesRoute';
import { seoRootBaseApi } from '@/services/base/seoRoute';
import { tasksApi } from '@/services/base/tasksRoute';
import {
  voucherBaseApi,
  voucherBaseApiWithTags,
} from '@/services/base/voucherBase';
import { cspEngagementBaseApi } from '@/services/cspEngagementApi';
import { focusedInstituteApi } from '@/services/focusedInstitute/service';
import { postQcApi } from '@/services/postQc/service';
import visaManagementReducer from 'components/Application/VisaSection/redux/VisaManagementReducer';
import userManagementReducer from 'components/UserManagement/redux/UsermanagementReducer';

import confirmDialogSlice from '../features/confirmDialogSlice';
import generalSlice from '../features/generalSlice';
import { rtkQueryErrorLogger } from '../utils/service';

import applicationDetailsSlice from './features/applicationDetails/applicationDetailsSlice';
import commissionSharedListSlice from './features/commissionSharedList/commissionSharedList';
import cspStudentSlice from './features/cspStudentSlice';
import geebeeSharedListSlice from './features/geebeeSharedList/geebeeSharedListSlice';
import modalsSlice from './features/modals/modalsSlice';
import sharedListSlice from './features/sharedList/sharedListSlice';
import taskSlice from './features/tasks/taskSlice';
import userSlice from './features/user/userSlice';
// ...

export const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    mapping: mappingSlice,
    user: userSlice,
    userManagement: userManagementReducer,
    visaManagement: visaManagementReducer,
    [geebeeBaseApi.reducerPath]: geebeeBaseApi.reducer,
    [documentUploadBaseApi.reducerPath]: documentUploadBaseApi.reducer,
    [commissionApi.reducerPath]: commissionApi.reducer,
    general: generalSlice,
    confirmDialog: confirmDialogSlice,
    sharedList: sharedListSlice,
    geebeeSharedList: geebeeSharedListSlice,
    userInfoList: userInfoListSlice,
    commissionSharedList: commissionSharedListSlice,
    modals: modalsSlice,
    uploadDocuments: uploadDocuments,
    miscDocuments: miscDocSlice,
    [seoRootBaseApi.reducerPath]: seoRootBaseApi.reducer,
    [bookmarkListBaseApi.reducerPath]: bookmarkListBaseApi.reducer,
    [tasksApi.reducerPath]: tasksApi.reducer,
    [applicationQcApi.reducerPath]: applicationQcApi.reducer,
    tasksData: taskSlice,
    [universityApi.reducerPath]: universityApi.reducer,
    [commisionFileUploadApi.reducerPath]: commisionFileUploadApi.reducer,
    [cmsBaseApiWithTags.reducerPath]: cmsBaseApiWithTags.reducer,
    [rulesBaseApiWithTags.reducerPath]: rulesBaseApiWithTags.reducer,
    joyride: joyRideSlice,
    cfStateSlice: cfStateSlice.reducer,
    courseFinderSlice: courseFinderSlice.reducer,
    [metabaseApiWithTags.reducerPath]: metabaseApiWithTags.reducer,
    [voucherBaseApiWithTags.reducerPath]: voucherBaseApiWithTags.reducer,
    [focusedInstituteApi.reducerPath]: focusedInstituteApi.reducer,
    [postQcApi.reducerPath]: postQcApi.reducer,
    cspStudentDataSlice: cspStudentSlice,
    applicationDetails: applicationDetailsSlice,
    [cspEngagementBaseApi.reducerPath]: cspEngagementBaseApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: [
          `confirmDialog/confirmDialog`,
          `uploadDocuments/uploadDocuments`,
        ],
        // Ignore these paths in the state
        ignoredPaths: [
          `confirmDialog.onSubmit`,
          `confirmDialog.onCancel`,
          `uploadDocument.onModalClose`,
        ],
      },
    }).concat(
      geebeeBaseApi.middleware,
      commissionApi.middleware,
      documentUploadBaseApi.middleware,
      seoRootBaseApi.middleware,
      bookmarkListBaseApi.middleware,
      applicationQcApi.middleware,
      tasksApi.middleware,
      sagaMiddleware,
      commisionFileUploadApi.middleware,
      cmsBaseApiWithTags.middleware,
      rtkQueryErrorLogger,
      rulesBaseApiWithTags.middleware,
      metabaseApiWithTags.middleware,
      voucherBaseApi.middleware,
      focusedInstituteApi.middleware,
      postQcApi.middleware,
      cspEngagementBaseApi.middleware,
    ),
  enhancers: [applyMiddleware(sagaMiddleware)],
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
