import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ConfirmModalOptionsType = {
  proceedButtonLoading?: boolean;
  proceedButtonText?: string;
  cancelButtontext?: string;
};

export enum CONFIRM_MODAL_TYPES {
  DEFAULT = `DEFAULT`,
  COMMISSION_WARNING = `COMMISSION_WARNING`,
}

export type ConfirmModalStateType = {
  message: string;
  title: string;
  type?: keyof typeof CONFIRM_MODAL_TYPES;
  meta?: Record<string, any>;
  onSubmit: undefined | (() => void);
  onCancel?: undefined | (() => void);
  options?: ConfirmModalOptionsType;
};

const initialState = {} as ConfirmModalStateType;

const confirmDialogSlice = createSlice({
  name: `confirmDialog`,
  initialState,
  reducers: {
    close: (state) => {
      state.onSubmit = undefined;
      state.onCancel = undefined;
    },
    confirmDialog: (state, action: PayloadAction<ConfirmModalStateType>) => {
      state.message = action.payload.message;
      state.title = action.payload.title;
      state.onSubmit = action.payload.onSubmit;
      state.onCancel = action.payload.onCancel;
      state.options = action.payload.options;
      state.type = action.payload.type;
      state.meta = action.payload.meta;
    },
  },
});

export const { close, confirmDialog } = confirmDialogSlice.actions;

export default confirmDialogSlice.reducer;
