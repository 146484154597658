import React, { useContext, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { CircularProgress, IconButton } from '@mui/material';
import { IMessage, useNotifications } from '@novu/notification-center';

import { NoNotification } from '../Icons';

import { NotificationCategory, NotificationVariant } from './notification.enum';
import NotificationTemplate from './NotificationTemplate';
import { notificationTemplateMapper } from './notificationTemplateConstant';

interface NotificationListProps {
  storeId?: NotificationCategory;
  containerId: string;
  compactView?: boolean;
  enableScrollTopTop?: boolean;
}

const NotificationList = (props: NotificationListProps) => {
  const {
    notifications,
    fetchNextPage,
    hasNextPage,
    refetch,
    markAsSeen,
    fetching,
  } = useNotifications({
    storeId: props.storeId,
  });

  const [reachedBottom, setReachedBottom] = useState<boolean>(false);
  const scrollDivRef = useRef<InfiniteScroll>(null);

  useEffect(() => {
    refetch();
  }, []);

  const notificationClickHandler = (
    notificationId: string,
    redirect?: boolean,
  ) => {
    const notification: IMessage | undefined = notifications.find(
      ({ _id }) => _id === notificationId,
    );
    if (!notification) return;

    //check for seen
    if (!notification.seen) {
      markAsSeen(notificationId);
      refetch();
    }

    //redirect to application
    if (redirect) {
      if (notification?.payload?.notificationCategory === `GIC_DOCUMENT`) {
        window.location.replace(
          `/student/${notification?.payload?.studentId}?active=VAS`,
        );
      }
      if (
        [
          `SHORTLIST_REQUEST_PENDING`,
          `SHORTLIST_REQUEST_PROCESSED`,
          `SHORTLIST_REQUEST_DELETED`,
          `SHORTLIST_REQUEST_STATUS`,
        ].includes(notification?.payload?.notificationCategory as string)
      ) {
        window.location.replace(
          `/student/${notification?.payload?.studentId}?active=SHORTLIST#Shortlist-${notification?.payload?.shortListRequestId}`,
        );
      } else if (
        notification?.payload?.notificationCategoryGroup === `DOCUMENT`
      ) {
        window.location.replace(
          `/student/${notification?.payload?.studentId}?active=DOCUMENTS`,
        );
      } else {
        window.open(`/view-application/${notification.payload.applicationId}`);
      }
    }
  };

  const scrollToTop = () => {
    scrollDivRef.current
      ?.getScrollableTarget()
      ?.scroll({ top: 0, behavior: `smooth` });
  };

  return (
    <div>
      {props.enableScrollTopTop && reachedBottom && (
        <IconButton
          className="h-10 w-10 rounded-full absolute right-9 bottom-10 p-4 bg-grey-200 drop-shadow-lg border-4 cursor-pointer"
          onClick={scrollToTop}
        >
          <KeyboardArrowUpIcon color="primary" />
        </IconButton>
      )}
      <InfiniteScroll
        ref={scrollDivRef}
        dataLength={(notifications || []).length}
        scrollableTarget={props.containerId}
        next={fetchNextPage}
        hasMore={!props.compactView && hasNextPage}
        loader={
          <div className="flex items-center justify-center my-4 p-4">
            <CircularProgress style={{ width: `2rem` }} size={`small`} />
          </div>
        }
        onScroll={(e: MouseEvent) => {
          const parentHeight = (e.target as HTMLDivElement)?.clientHeight;
          const scrolledPosition = (e.target as HTMLDivElement)?.scrollTop;
          if (scrolledPosition > parentHeight) {
            setReachedBottom(true);
          } else {
            setReachedBottom(false);
          }
        }}
      >
        <div className="pb-8">
          {notifications ? (
            notifications.length === 0 && !fetching ? (
              <div className="flex items-center justify-center  w-full flex-col mt-9">
                <NoNotification />
                <p className="text-lg font-medium">
                  You Have No Notification At The Moment
                </p>
              </div>
            ) : (
              notifications.map((notification, index) => {
                const { title, body, footNote } = notificationTemplateMapper(
                  notification.templateIdentifier as NotificationCategory,
                  notification.payload,
                );

                return (
                  <NotificationTemplate
                    key={index}
                    title={title}
                    body={body}
                    footNote={footNote}
                    variant={
                      props.compactView
                        ? NotificationVariant.outlined
                        : NotificationVariant.contained
                    }
                    category={
                      (notification.templateIdentifier === `REMARKS` &&
                      notification.payload.notificationCategory !== `REMARKS`
                        ? `SHORTLIST`
                        : notification.templateIdentifier) as NotificationCategory
                    }
                    seen={notification.seen}
                    id={notification._id}
                    createdAt={notification.createdAt}
                    onNotificationClick={notificationClickHandler}
                  />
                );
              })
            )
          ) : null}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default NotificationList;
