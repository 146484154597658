import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { transformResponse } from '@/services/util';
import {
  AddNewCspEngagementMeetingRequestType,
  AddNewCspEngagementMeetingResponseType,
  GetCspMeetingDetailsRequestType,
  GetCspMeetingDetailsResponseType,
  GetCspMeetingFeedbackSopRequestType,
  GetCspMeetingFeedbackSopResponseType,
  SearchCspEngagementMeetingsRequestType,
  SearchCspEngagementMeetingsResponseType,
} from '@/types/cspEngagement';

export const cspEngagementBaseApi = createApi({
  reducerPath: `cspEngagementBaseApi`,
  baseQuery: fetchBaseQuery({
    baseUrl: `/api/geebeeRoute?path=csp-engagement-meeting`,
  }),
  endpoints: () => ({}),
});

export const cspEngagementBaseApiWithTags =
  cspEngagementBaseApi.enhanceEndpoints({
    addTagTypes: [],
  });

export const cspEngagementApi = cspEngagementBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    searchCspEngagementMeetings: builder.mutation<
      SearchCspEngagementMeetingsResponseType,
      SearchCspEngagementMeetingsRequestType
    >({
      query: ({ cspId, ...body }) => {
        return {
          url: `/search/csp/${cspId}`,
          method: `POST`,
          body,
        };
      },
      transformResponse,
    }),
    addNewCspEngagementMeeting: builder.mutation<
      AddNewCspEngagementMeetingResponseType,
      AddNewCspEngagementMeetingRequestType
    >({
      query: ({ cspId, ...body }) => {
        return {
          url: `/csp/${cspId}`,
          method: `POST`,
          body,
        };
      },
      transformResponse,
    }),
    getCspMeetingFeedbackSop: builder.query<
      GetCspMeetingFeedbackSopResponseType,
      GetCspMeetingFeedbackSopRequestType
    >({
      query: ({ cspId }) => `/feedback-sop/csp/${cspId}`,
      transformResponse,
    }),
    getCspMeetingDetails: builder.query<
      GetCspMeetingDetailsResponseType,
      GetCspMeetingDetailsRequestType
    >({
      query: ({ meetingId }) => `/${meetingId}`,
      transformResponse,
    }),
  }),
});

export const {
  useSearchCspEngagementMeetingsMutation,
  useAddNewCspEngagementMeetingMutation,
  useGetCspMeetingFeedbackSopQuery,
  useGetCspMeetingDetailsQuery,
} = cspEngagementApi;
